require("./bootstrap");
// window.Vue = require("vue");

// For Alerts
// import Buefy from "buefy";
// Vue.use(Buefy);

// Load Components

// Vue.component('laratoaster', require('./components/LaraToaster.vue').default, { name: 'laratoaster' });
// Vue.component(
//   "pay-with-stripe",
//   require("./components/Payments/PayWithStripe.vue").default,
//   { name: "pay-with-stripe" },
// );
// Vue.component(
//   "pay-with-payzone",
//   require("./components/Payments/PayWithPayZone.vue").default,
//   { name: "pay-with-payzone" },
// );
// Vue.component(
//   "pay-with-paypal",
//   require("./components/Payments/PayWithPayPal.vue").default,
//   { name: "pay-with-paypal" },
// );
// Vue.component("outpost", require("./components/Mailroom/Outpost.vue").default, {
//   name: "outpost",
// });
//
// const app = new Vue({
//   el: "#app",
// });
