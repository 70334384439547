window._ = require('lodash');
window.Popper = require('popper.js').default;

try {
	global.$ = global.jQuery = require('jquery');
	require('bootstrap');
} catch (e) {}

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
	window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
	console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

$(document).ready(function() {
	$( ".menuToggle" ).click(function() {
		$( ".mainNav > ul" ).slideToggle();
	});

	$('.message').delay(8000).fadeOut();

	$( ".close-btn" ).click(function(event) {
		$( '.message' ).hide();
	});
})

$(document).ready(function(){

	var lines = 7;
	var linesUsed = $('#linesUsed');
	$('#forwarding_address').keydown(function(e) {
		newLines = $(this).val().split("\n").length;
		linesUsed.text(newLines);
		if(e.keyCode == 13 && newLines >= lines) {
			linesUsed.css('color', 'red');
			return false;
		}
		else {
			linesUsed.css('color', '');
		}
	});

	var maxLength = 25;
	$('#forwarding_address').keydown(function() {
		var text = $(this).val();
		var line = text.split(/(\r\n|\n|\r)/gm);
		for (var i = 0; i < line.length; i++) {
			if (line[i].length > maxLength) {
				line[i] = line[i].substring(0, maxLength);
			}
		}
		$(this).val(line.join(''));
	});
});


// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
// 	broadcaster: 'pusher',
// 	key: '123954ada9b8f75e64af',
// 	cluster: 'eu',
// 	encrypted: true
// });